export const psychotherapistCommentsData = [
  {
    img: 'person1.svg',
    imgAlt: 'Портрет девушки в очках с черными волосами на красном фоне',
    name: 'Ольга',
    age: '28 лет',
    title:
      'Тревога теперь посещает меня гораздо реже и я знаю, как с ней справляться',
    desc: 'Я долго искала подходящего терапевта и нашла его здесь. Сессии были структурированными и очень комфортными. Тревога теперь посещает меня гораздо реже и я знаю, как с ней справляться'
  },
  {
    img: 'person12.svg',
    imgAlt: 'Портрет рыжеволосого молодого человека на синем фоне',
    name: 'Сергей',
    age: '29 лет',
    title: 'Широкий выбор специалистов — от психотерапевтов до коучей',
    desc: 'Сервис предлагает широкий выбор специалистов — от психотерапевтов до коучей. Я нашел именно то, что искал.'
  },
  {
    img: 'person8.svg',
    imgAlt: 'Портрет темноволосой девушки с длинными волосами на зеленом фоне',
    name: 'Вика',
    age: '35 лет',
    title: 'Оказалось, стоило лишь найти своего психотерапевта',
    desc: 'До последнего думала, что мне не подходит онлайн формат работы с психологом, оказалось, стоило лишь найти своего психотерапевта!) Пока не рассматриваю возвращение к очному формату. В YouTalk очень удобно записываться на сессии прямо с телефона, в личном кабинете могу посмотреть свободное время моего психолога и даже написать ему, если нужно что-то уточнить.'
  },
  {
    img: 'person6.svg',
    imgAlt: 'Портрет женщины со светлыми волосами на синем фоне',
    name: 'Антонина',
    age: '36 лет',
    title: 'Окружили меня заботой и были рядом в самый сложный для меня период',
    desc: 'Рекомендую всем, кто ищет поддержку. Самый эмпатичный сервис, который я встречала. Все сотрудники, начиная со службы поддержки и заканчивая моим замечательным терапевтом, буквально окружили меня заботой и были рядом в самый сложный для меня период…'
  },
  {
    img: 'person13.svg',
    imgAlt: 'Портрет мужчины с короткими темными волосами на желтом фоне',
    name: 'Кирилл',
    age: '25 лет',
    title: 'Сервис сэкономил мне массу времени и усилий',
    desc: 'Я ценю свое время, и этот сервис сэкономил мне массу времени и усилий. Отдельное спасибо за функцию напоминаний о сеансах в приложении – я теперь не пропускаю встречи.'
  },
  {
    img: 'person0.svg',
    imgAlt: 'Портрет девушки с розовыми волосами на зеленом фоне',
    name: 'Людмила',
    age: '31 год',
    title:
      'Предложили помощь в поиске другого специалиста и ответили на все мои вопросы',
    desc: 'Отличный сервис! Когда я пыталась записаться к специалисту, мне написали в WhatsApp и сообщили, что он не принимает новых клиентов. Однако они предложили помощь в поиске другого специалиста и ответили на все мои вопросы, общаясь очень вежливо. Рекомендую этот сервис!'
  }
]
